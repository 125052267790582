<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form label-width="120px" :model="form">
        <el-form-item label="申请批次">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="申请数量">
            <el-input v-model="form.region"></el-input>
        </el-form-item>
        <el-form-item label="选择工厂">
            <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="产品型号">
            <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="申请理由">
            <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="申请类型">
            <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="sn码前缀">
            <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="sn码后缀起始码">
            <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="sn后缀的位数">
            <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="固件版本">
            <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="">
            <el-button type="primary" @click="addconfirm">新增</el-button>
            <el-button type="success" @click="pass">通过</el-button>
            <el-button type="danger" @click="reject">拒绝</el-button>
        </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      form: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    addconfirm(){

    },
    goback (){
        this.$router.push('/iot/production')
    },
    pass (){
        
    },
    reject (){
        
    },
  },
  filters: {},
  mounted () { },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.el-form {
    width: 600px
}
</style>
